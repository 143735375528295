import { isMobileDevice } from 'src/components/WidgetMaker/WidgetDnD/isMobileDevice';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { useSSRSelector } from '@/redux/ssrStore';
import { EditableText } from '../../RichTextImage/component/TextEditor';
import SocialMediaIcons from './SocialMediaicon';
import RenderAppDownload from './RenderAppDownload';
import { ICON_COMPONENT } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/constant';
import { FOOTER_CHILDREN_SECTION_CONFIGS } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/NewFooterConfig';

const LogoAboutUs = (props: any) => {
  const { layer, index } = props;
  const isMobile = isMobileDevice();
  const {
    widgetContextState: { activeFooterData },
  } = useWidgetDndContextData();
  const store = useSSRSelector((state) => state.storeReducer.store);
  const storeName = store?.store_info?.name;
  const componentData = activeFooterData?.footerPropsConfig?.[layer]?.data?.[index];
  const logoData = componentData?.section?.[0];
  const aboutUsData = componentData?.section?.[1];
  const appDownload = componentData?.section?.[2];
  const socialMedia = componentData?.section?.[3];

  function getImageLayoutSize(desktopSize, mobileSize) {
    const splitAspectRatio = logoData?.aspectRatio?.split(' / ');
    if (!splitAspectRatio) {
      return {};
    }
    const aspectRatio = splitAspectRatio[0] / splitAspectRatio[1];
    const width = isMobile ? mobileSize : desktopSize;
    return { width: width, height: aspectRatio ? width * (1 / aspectRatio) : width };
  }

  function handleLearnMore(url, isNewTab) {
    if(url) {
    if (isNewTab) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  }
  }

  function renderLogoAndStoreName() {
    const { logoUrl, desktopSize, mobileSize, hideLogo, hideStoreName } = logoData;
    return (
      <div className="tw-flex tw-items-center tw-gap-[16px]">
        {!hideLogo && (logoData.logoUrl || logoData.logoSrc) && (
          <img src={logoUrl} {...getImageLayoutSize(desktopSize, mobileSize)} />
        )}
        {!hideStoreName && (
          <div className="tw-text-[16px] tw-font-semibold">{storeName}</div>
        )}
      </div>
    );
  }

  function renderAboutUsSection() {
    const { content, showMoreLink, linkText, linkColor, redirectUrl, isOpenInNewTab } =
      aboutUsData;

    return (
      <div
        className={`${!logoData?.hideLogo && (logoData.logoUrl || logoData.logoSrc) ? (isMobile ? 'tw-mt-[20px]' : 'tw-mt-[32px]') : ''}
       tw-flex tw-flex-col ${isMobile ? 'tw-gap-[8px]' : 'tw-gap-[16px]'} tw-mb-[36px] ${!logoData?.hideStoreName ? (isMobile ? 'tw-mt-[20px]' : 'tw-mt-[32px]') : ''}`}
      >
        <div>
          <EditableText
            content={content}
            className="tw-font-normal tw-leading-[22.4px]"
          ></EditableText>
        </div>
        {showMoreLink && (
          <div
            onClick={() => handleLearnMore(redirectUrl, isOpenInNewTab)}
            style={{ color: linkColor }}
            className="tw-mt-[-4px] tw-flex tw-cursor-pointer tw-items-center tw-gap-[12px] tw-text-[13px] tw-font-semibold"
          >
            <div>{linkText}</div>
            {/* <div style={{background: activeFooterData?.footerPropsConfig?.footerConfig?.color}} className="tw-h-[10px] tw-w-[10px] tw-rounded-[50%]"></div> */}
          </div>
        )}
      </div>
    );
  }

  function socialMediaIcons() {
    return (
      <div className={'tw-mb-[24px]'}>
        <SocialMediaIcons
          componentData={socialMedia}
          activeFooterData={activeFooterData}
        />
      </div>
    );
  }

  function appDownloadIcons() {
    return (
      <div>
        <RenderAppDownload
          componentData={appDownload}
          activeFooterData={activeFooterData}
        />
      </div>
    );
  }

  return (
    <div
      style={
        isMobile
          ? { width: '100%' }
          : FOOTER_CHILDREN_SECTION_CONFIGS[componentData?.type].style || {}
      }
    >
      {renderLogoAndStoreName()}
      {renderAboutUsSection()}
      {socialMedia?.columnType === ICON_COMPONENT.LOGO &&
        socialMedia?.isVisible &&
        socialMediaIcons()}
      {appDownload?.columnType === ICON_COMPONENT.LOGO &&
        appDownload?.isVisible &&
        appDownloadIcons()}
    </div>
  );
};

export default LogoAboutUs;
