import { isMobileDevice } from 'src/components/WidgetMaker/WidgetDnD/isMobileDevice';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { EditableText } from '../../RichTextImage/component/TextEditor';
import { useEffect, useState } from 'react';
import { AccordionCloseIcon, AccordionOpenIcon } from 'src/components/MegaMenu/svgIcons';
import { PATH, getRoute } from 'src/utils/routes';
import { useSSRSelector } from 'src/redux/ssrStore';
import { useRouter } from 'next/router';
import { routeToCollectionPage } from 'src/utils/routeToCollectionPage';
import { isPLPRequiredSystem } from 'src/utils/isPLPRequiredSystem';
import { AccordionComponent } from './Accordion';
import { FOOTER_CHILDREN_SECTION_CONFIGS } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/NewFooterConfig';
import { getMergedShopSectionData } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/Utils';
import { useOldNavLinksList } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/HeaderBuilder/components/utils';

const RenderLinksList = (props: any) => {
  const { layer, index } = props;
  const isMobile = isMobileDevice();
  const store = useSSRSelector((state) => state.storeReducer.store);
  const router = useRouter();
  const [hideDropdown, setHideDropdown] = useState([]);
  const [listData, setListData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const {
    widgetContextState: { activeFooterData },
  } = useWidgetDndContextData();
  const { categoriesList, collectionCategoriesList } = useOldNavLinksList();
  const footerConfig = activeFooterData?.footerPropsConfig?.footerConfig;
  const color =
    footerConfig?.colorSchemes?.[footerConfig?.selectedColorSchemeIndex]?.text;
  const componentData = activeFooterData?.footerPropsConfig?.[layer]?.data?.[index];
  const isShopComponentLinks = componentData?.subType === 'shop';

  useEffect(() => {
    const allSectionLinks = isShopComponentLinks
      ? getMergedShopSectionData(
          componentData?.section,
          collectionCategoriesList,
          categoriesList
        )
      : componentData?.section;
    const visibleLink = allSectionLinks?.filter((data) => data?.showOnHeader);
    const linkToShow = visibleLink?.slice(0, 10);
    if (visibleLink?.length > 10) {
      const moreLinks = {
        type: 'dropdown',
        name: 'More',
        showOnHeader: true,
        option: visibleLink?.slice(10, visibleLink?.length),
      };
      linkToShow.push(moreLinks);
    }

    setListData(linkToShow);
  }, [componentData?.section, componentData?.section?.length]);

  const redirectLink = (link: string, openInNewTab = false) => {
    if (openInNewTab) {
      window.open(link, '_blank');
    } else {
      window.location.replace(link);
    }
  };

  function redirectCategories(categoryId) {
    router.push(
      getRoute(
        `${
          isPLPRequiredSystem(store?.theme?.theme_class) ? PATH.SHOP : PATH.PRODUCT
        }?cid=${categoryId}`,
        store?.store_info?.domain
      ),
      undefined,
      { shallow: true }
    );
  }

  function handleRowClick(type, name, data) {
    const {
      redirectionLink = '',
      redirectNewPage = 'false',
      linkType = '',
      id = '',
    } = data;
    if (type === 'dropdown') {
      const updatedDropdown = [...hideDropdown];
      if (hideDropdown?.includes(name)) {
        const currentIndex = updatedDropdown?.indexOf(name);
        updatedDropdown?.splice(currentIndex, 1);
        setHideDropdown(updatedDropdown);
      } else {
        updatedDropdown?.push(name);
        setHideDropdown(updatedDropdown);
      }
    } else {
      if (!isShopComponentLinks && redirectionLink) {
        redirectLink(redirectionLink, redirectNewPage);
      } else if (linkType === 'collection') {
        if (redirectionLink) {
          redirectLink(redirectionLink, redirectNewPage);
        } else {
          routeToCollectionPage({ id });
        }
      } else {
        if (redirectionLink) {
          redirectLink(redirectionLink, redirectNewPage);
        } else {
          redirectCategories(id);
        }
      }
    }
  }

  function renderHeading() {
    const { content } = componentData;
    return (
      <div>
        <EditableText content={content} className="tw-font-[500]"></EditableText>
      </div>
    );
  }

  function renderDropdown(option: any) {
    return (
      <div className="tw-ml-[5px] tw-mt-[16px] tw-flex tw-cursor-pointer tw-flex-col tw-gap-[16px]">
        {option?.map((data, index) => {
          const { name, showOnHeader } = data;
          return (
            showOnHeader && (
              <span
                onClick={() => handleRowClick('text', name, data)}
                key={name + index}
                className="tw-text-[14px] tw-font-normal"
              >
                {name}
              </span>
            )
          );
        })}
      </div>
    );
  }

  function renderLinks() {
    return (
      <div className={`tw-flex tw-flex-col tw-gap-[16px]`}>
        {listData?.map((data, index) => {
          const { name, type, showOnHeader, option = [] } = data;
          return (
            showOnHeader && (
              <div key={name + index}>
                <div
                  onClick={() => handleRowClick(type, name, data)}
                  className="tw-flex tw-cursor-pointer tw-items-center tw-gap-[4px]"
                >
                  <span className="tw-text-[14px] tw-font-normal">{name}</span>
                  {type === 'dropdown' &&
                    (hideDropdown?.includes(name) ? (
                      <AccordionCloseIcon secondaryColor={color} />
                    ) : (
                      <AccordionOpenIcon secondaryColor={color} />
                    ))}
                </div>
                {type === 'dropdown' &&
                  hideDropdown?.includes(name) &&
                  renderDropdown(option)}
              </div>
            )
          );
        })}
      </div>
    );
  }

  function renderAccordion() {
    return (
      <AccordionComponent
        headingComp={renderHeading}
        contentComp={renderLinks}
        color={color}
        isOpen={isOpen}
        setIsOpen={() => setIsOpen((prev) => !prev)}
      />
    );
  }

  return (
    <div
      style={
        isMobile
          ? { width: '100%' }
          : FOOTER_CHILDREN_SECTION_CONFIGS[componentData?.type].style || {}
      }
      className="tw-flex tw-flex-col tw-gap-[20px]"
    >
      {!isMobile && renderHeading()}
      {!isMobile && renderLinks()}
      {isMobile && renderAccordion()}
    </div>
  );
};
export default RenderLinksList;
