import router from 'next/router';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { useSSRSelector } from 'src/redux';
import { isPLPRequiredSystem } from 'src/utils/isPLPRequiredSystem';
import { getRoute, PATH } from 'src/utils/routes';
import { routeToCollectionPage } from 'src/utils/routeToCollectionPage';
import { EditableText } from '../../RichTextImage/component/TextEditor';
import { FOOTER_CHILDREN_SECTION_CONFIGS } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/NewFooterConfig';

const CategoryCollections = (props: any) => {
  const { layer, index, fontColor } = props;
  const {
    widgetContextState: { activeFooterData },
  } = useWidgetDndContextData();
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const componentData = activeFooterData?.footerPropsConfig?.[layer]?.data?.[index];

  const onCategoriesLinkHandle = (category, openInNewTab = false) => {
    const link = getRoute(
      `${
        isPLPRequiredSystem(storeData?.theme?.theme_class) ? PATH.SHOP : PATH.PRODUCT
      }?cid=${category.id}`,
      storeData?.store_info?.domain
    );
    if (openInNewTab) {
      window.open(
        window.location.origin + (!link?.startsWith('/') ? '/' + link : link),
        '_blank'
      );
      return;
    }
    router.push(link, undefined, { shallow: true });
  };

  const onClickHandler = (item) => {
    const { type, data, link, redirection } = item;
    switch (type) {
      case 'collection':
        routeToCollectionPage(data, redirection);
        break;
      case 'category':
        onCategoriesLinkHandle(data, redirection);
        break;
      case 'custom':
        !redirection ? (window.location.href = link) : window.open(link, '_blank');
        break;
    }
  };

  function renderHeading() {
    return (
      <div>
        <EditableText
          content={componentData?.content}
          className="tw-font-[500]"
          style={{ color: fontColor }}
        />
      </div>
    );
  }

  return (
    <div style={FOOTER_CHILDREN_SECTION_CONFIGS[componentData?.type].style || {}}>
      {renderHeading()}
      <div className="tw-mt-[8px] tw-flex tw-flex-wrap tw-gap-[8px]">
        {componentData?.linksList
          ?.filter((item) => item.isVisible)
          .map((item, index, arr) => {
            return (
              <div
                className="tw-mt-[8px] tw-flex tw-items-center tw-gap-[8px]"
                key={index}
              >
                <div
                  className="tw-cursor-pointer"
                  onClick={() => {
                    onClickHandler(item);
                  }}
                >
                  <p
                    className="tw-m-0 tw-whitespace-nowrap tw-text-[14px] tw-font-normal tw-leading-[22.4px]"
                    color={fontColor}
                  >
                    {item.name}
                  </p>
                </div>
                {index !== arr.length - 1 && (
                  <div
                    className="tw-h-[14px] tw-opacity-50"
                    style={{
                      border: `0.4px solid ${fontColor}`,
                    }}
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CategoryCollections;
