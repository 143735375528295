import React from 'react';

export const FooterGetIcon = ({ color, backgroundColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
    >
      <g id="Group 1171275750">
        <circle id="Ellipse 1154" cx="22" cy="22" r="22" fill={color} />
        <path
          id="Vector"
          d="M28.5996 21.1198L27.5088 22.2491L23.1454 17.7318V30.7998H21.5871V17.7318L17.2238 22.2491L16.1329 21.1198L22.3663 14.6665L28.5996 21.1198Z"
          fill={backgroundColor}
        />
      </g>
    </svg>
  );
};
