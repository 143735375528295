import React, { useEffect } from 'react';
import { useGetDeviceType } from 'src/hooks/useGetDeviceType';
import classNames from 'classnames';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { SECTION_TYPE } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/constant';
import { fetchFooterPaymentModesList } from 'src/redux/actions';
import { useDispatch } from 'react-redux';
import { useSSRSelector } from 'src/redux';

export function CopyrightAndPayment({ textColor, isFooterInView }) {
  const dispatch = useDispatch();
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';
  const {
    widgetContextState: { activeFooterData },
  } = useWidgetDndContextData();
  const { footerPaymentModesList, storeId } = useSSRSelector((state) => ({
    storeId: state.storeReducer.store?.store_id,
    footerPaymentModesList: state.commonReducer.footerPaymentModesList,
  }));

  const componentData = Object.values(activeFooterData?.footerPropsConfig || {})?.find(
    (item) => item?.isStaticSection
  )?.data;

  useEffect(() => {
    if (isFooterInView && !footerPaymentModesList?.length && storeId) {
      dispatch(fetchFooterPaymentModesList(storeId));
    }
  }, [isFooterInView, footerPaymentModesList, storeId]);

  if (!componentData) {
    return null;
  }
  return (
    <div
      className={classNames(
        'tw-flex tw-items-center',
        isMobile
          ? 'tw-flex-col tw-items-center tw-justify-center tw-gap-[24px] tw-px-[20px] tw-py-[32px]'
          : 'tw-flex-row tw-justify-between tw-gap-[100px] tw-px-[40px] tw-py-[21.75px]'
      )}
      style={{
        borderTop: '0.7px solid rgba(255,255,255,0.3)',
      }}
    >
      {componentData?.map((staticLayer) => {
        if (staticLayer?.isVisible && staticLayer.type === SECTION_TYPE.COPYRIGHT) {
          return (
            <p
              key={staticLayer.type}
              className={classNames(
                'tw-m-0 tw-whitespace-nowrap tw-font-normal',
                !isMobile
                  ? 'tw-mr-[22px] tw-text-[12px] tw-leading-[16.8px]'
                  : 'tw-text-[10px]  tw-leading-[14.8px]'
              )}
              style={{ color: textColor }}
            >
              {staticLayer?.content}
            </p>
          );
        }
        if (
          staticLayer?.isVisible &&
          staticLayer.type === SECTION_TYPE.PAYMENT_MODES &&
          !!footerPaymentModesList?.length
        ) {
          return (
            <div
              key={staticLayer.type}
              className="tw-flex tw-flex-wrap tw-items-center tw-gap-[4px]"
            >
              {footerPaymentModesList.map((imageCDN) => {
                return (
                  <div
                    key={imageCDN}
                    className="tw-h-[24px] tw-w-[40px] tw-overflow-hidden tw-rounded-[3px] tw-border-[1px] tw-border-solid tw-border-[#E2E4E7]"
                  >
                    <img src={imageCDN} className="tw-h-[100%] tw-w-[100%]" />
                  </div>
                );
              })}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}
